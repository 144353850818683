import React from "react"
import { Button, Col, List, Row, Typography } from "antd"
import {
  EnvironmentOutlined,
  GlobalOutlined,
  PhoneOutlined,
} from "@ant-design/icons"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Shell from "../components/Shell/Shell"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import { darken, lighten } from "../utils/color"
import {
  clickablePhoneNumber,
  prettifyPhoneNumber,
} from "../utils/phoneNumbers"

const description = `Get Outside Adventure Center of Asheville 1 Resort Drive, Asheville Enjoy expansive views of the city skyline from Asheville Zipline Canopy Adventures, or head up into the trees for a challenge at Asheville Treetops Adventure Park. Jump on a mountain bike to experience Kolo Bike Park, zipline with your kids on KidZip, “Climb” the TreeQuest Read more...`

const mapsLink = address =>
  `https://google.com/maps/place/${address.replace(/ /g, "+")}`

const tan = `#F2F0E9`

const centeredRow = {
  align: `middle`,
  justify: `center`,
  type: `flex`,
}

const normalCol = {
  xs: 23,
  sm: 22,
  md: 20,
  lg: 18,
  xl: 16,
  xxl: 14,
}

const cardStyle = {
  background: lighten(tan, 7),
  border: `1px solid ${darken(tan, 2)}`,
  borderRadius: 4,
  boxShadow: `0 4px 8px rgba(0,0,0,0.1)`,
  margin: `24px auto`,
  padding: 16,
}

const Title = ({ name }) => (
  <span style={{ fontSize: 24, marginBottom: 0 }}>{name}</span>
)

const ItemMeta = ({ address, phone }) => (
  <>
    {phone ? prettifyPhoneNumber(phone) : null}
    {phone ? <br /> : null}
    <Typography.Text copyable style={{ color: `rgba(0,0,0,0.45)` }}>
      {address}
    </Typography.Text>
  </>
)

const ActivityList = ({ activites }) => (
  <List
    itemLayout="vertical"
    dataSource={activites}
    renderItem={(
      { address, description, name, phoneNumber, website },
      index
    ) => (
      <List.Item
        actions={[
          website ? (
            <a href={website}>
              <Button block ghost icon={<GlobalOutlined />} type="primary">
                Website
              </Button>
            </a>
          ) : null,
          address ? (
            <a href={mapsLink(address)}>
              <Button block ghost icon={<EnvironmentOutlined />} type="primary">
                Map
              </Button>
            </a>
          ) : null,
          phoneNumber ? (
            <a href={`tel:${clickablePhoneNumber(phoneNumber)}`}>
              <Button block ghost icon={<PhoneOutlined />} type="primary">
                Call
              </Button>
            </a>
          ) : null,
        ]}
        key={index}
        style={cardStyle}
      >
        <List.Item.Meta
          description={<ItemMeta address={address} />}
          title={<Title name={name} />}
        ></List.Item.Meta>
        <div style={{ marginBottom: 12 }}>{description}</div>
      </List.Item>
    )}
  />
)

const outdoorActivities = [
  {
    address: `1 Resort Drive, Asheville`,
    description:
      "Enjoy expansive views of the city skyline from Asheville Zipline Canopy Adventures, or head up into the trees for a challenge at Asheville Treetops Adventure Park. Jump on a mountain bike to experience Kolo Bike Park, zipline with your kids on KidZip, “Climb” the TreeQuest trees, or free-fall off a 75-foot tower. Hours vary in winter, depending on weather conditions, so call (828) 225-2921 to be sure the facility is open. Reservations are suggested for all activities by calling (877) 247-5539.",
    name: `Adventure Center of Asheville`,
    phoneNumber: `(877) 247-5539`,
    website: `https://ashevilletreetopsadventurepark.com/`,
  },
  {
    address: `1080 Ski Lodge Road, Maggie Valley`,
    description:
      "With 18 trails, a full-service rental department offering over 4,000 sets of ski and snowboard equipment, plus a state-of-the-art boot drying system, Cataloochee is one of the biggest ski areas in the mountains. The Mountain is open early November – late March, depending on temperature. Lift tickets and rentals must be booked online, and reservations are required in 2021; plan ahead, since the wait for a reservation can be as long as two weeks. To make a reservation or check on current conditions and availability, visit the website or call (828) 926-0285.",
    name: `Cataloochee Ski Area`,
    phoneNumber: `(828) 926-0285`,
    website: `https://cataloochee.com/`,
  },
  {
    address: `865 Willow Road, Hendersonville`,
    description:
      "Moonshine Mountain offers some of the best and steepest snow tubing in Western North Carolina, with over 500 feet of fun & excitement for all ages. Reservations are not accepted, so be prepared to stand in line. Check the website or the Facebook page for wait times. Call for snow conditions: (828) 696-0333. Open Friday 4-10 p.m., Saturday 10 a.m.-8 p.m., and Sunday 10 a.m.-6 p.m.",
    name: `Moonshine Mountain Snow Tubing Park`,
    phoneNumber: `(828) 696-0333`,
    website: `https://www.moonshinemountain.com/`,
  },
  {
    address: `578 Valley View Circle, Mars Hill`,
    description:
      "The Wolf peaks at an elevation of 4700 feet and sits in the shadow of Big Bald Mountain. There are 72 acres of skiable terrain, snowmaking capability, 22 lighted runs with 2 double lifts, 2 quad lifts, and a surface lift. Enjoy 9 easy trails to practice on and explore, and 3 black diamond runs. Visit the website for more information, or call (828) 689-4111.",
    name: `Wolf Ridge Ski Resort`,
    phoneNumber: `(828) 689-4111`,
    website: `https://skiwolfridgenc.com/`,
  },
]

const indoorActivities = [
  {
    address: `2 South Pack Square`,
    description:
      "Be inspired by world-class special exhibitions and the Asheville Art Museum’s outstanding collection, showcasing the very best of 20th and 21st-century American art and the cultural heritage and contemporary art of Western North Carolina. The collection, containing more than 3,500 works in all media, is the only such resource in the region. This award-winning museum annually presents 12-16 exhibitions drawing from the permanent collection and borrowed works, and offers a wide variety of public programs for adults, children, and visitors of all ages. Capacity is limited due to Covid-19, and timed admission tickets are required and may be purchased online. Regular Museum hours are Mondays, and Wednesdays through Sundays from 11 a.m. – 6 p.m.",
    name: `Asheville Art Museum`,
    phoneNumber: `828-253-3227`,
    website: `https://www.ashevilleart.org/`,
  },
  {
    address: `1 Battle Square, Suite 1A`,
    description:
      "The pinball museum has 35 pinball machines that you can play as often as you’d like for one set price (Adults-$15/Children 10 & Under-$12). Open Monday 1-6 p.m., Wednesday, Thursday & Friday 2 – 9 p.m., Saturday 12-9 p.m., and Sunday 1 – 6 p.m. *As of January 2021, the museum is closed. Please check the website or call (828) 776-5671 to confirm operating hours.",
    name: `Asheville Pinball Museum`,
    phoneNumber: `(828) 776-5671`,
    website: `https://pinball.ashevillepinball.com/`,
  },
  {
    address: `675 Merrimon Avenue`,
    description:
      "Asheville Pizza & Brewing is not just a great pizza restaurant; it’s also home to a movie theater, classic video game arcade, and an award-winning microbrewery. Hours are Monday – Friday, 3 – 8 p.m., and Saturday and Sunday 1 – 8 p.m. Outdoor dining, carryout, and delivery is available. As of January 2021, the theater is not showing movies, so visit the website or call (828) 254-1281 for updates.",
    name: `Asheville Pizza & Brewing`,
    phoneNumber: `(828) 254-1281`,
    website: `https://www.ashevillebrewing.com/location/north/`,
  },
  {
    address: `43 Patton Ave.`,
    description:
      "An Asheville institution for more than 50 years, the museum houses a gem and mineral collection which has garnered worldwide acclaim. In addition to the Colburn Hall of Minerals, the museum offers permanent and traveling exhibits and displays, as well as activities and manipulatives for children. It is open at different times throughout the week, and admissions are timed. To schedule a time to visit, check out the website.",
    name: `Asheville Museum of Science`,
    phoneNumber: `(828) 254-7162`,
    website: `https://ashevillescience.org/`,
  },
  {
    address: `16 N Liberty St`,
    description:
      "Relax and recharge in the nature-inspired salt cave. Made up of 20 tons of pure pink salt, the cave mocks the microclimate of a salt mine, with all of its antibacterial, uncontaminated, toxin- and pollutant-free properties. Relax in a zero gravity chair, while ambient music is infused in the space to help you to drift off into your own meditation. With several choices of sessions ranging from private alone time to family-friendly and community gatherings, it’s an experience like no other. Book your session on the cave’s website.",
    name: `Asheville Salt Cave`,
    phoneNumber: `(828) 236-5999`,
    website: `https://ashevillesaltcave.com/salt-cave/`,
  },
]

export default ({ data: { hero, hotel, image }, location }) => (
  <Shell>
    <SEO
      meta={{
        description,
        image: hero.fluid.src,
        imageAlt: hero.description,
      }}
      title="Winter Activities in Asheville, NC - The Residences at Biltmore"
      url={location.href}
    />
    <Hero contentfulAsset={hero} height="30vh" />
    <BookingBar />
    <Row {...centeredRow} style={{ marginTop: 32 }}>
      <Col {...normalCol} style={{ textAlign: `center` }}>
        <Typography.Title style={{ marginTop: 24 }}>
          Winter Activities in Asheville, NC
        </Typography.Title>
        <Img
          alt={image.description}
          fluid={image.fluid}
          style={{ margin: `auto`, maxWidth: 600 }}
        />
      </Col>
    </Row>
    <Row {...centeredRow} style={{ marginTop: 32 }}>
      <Col {...normalCol}>
        <Typography.Title level={3}>Get Outside</Typography.Title>
      </Col>
      <Col {...normalCol}>
        <ActivityList activites={outdoorActivities} />
      </Col>
    </Row>
    <Row {...centeredRow} style={{ marginTop: 32 }}>
      <Col {...normalCol}>
        <Typography.Title level={3}>Cozy Up Indoors</Typography.Title>
      </Col>
      <Col {...normalCol}>
        <ActivityList activites={indoorActivities} />
      </Col>
    </Row>
    <Row {...centeredRow} style={{ marginTop: 32 }}>
      <Col {...normalCol}>
        <Typography.Paragraph>
          Are you ready for a winter getaway to the mountains? Call us to book
          your stay:{" "}
          <a href={`tel:${hotel.phone}`}>{prettifyPhoneNumber(hotel.phone)}</a>.
        </Typography.Paragraph>
      </Col>
    </Row>
  </Shell>
)

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "downtown-asheville-hero" }) {
      ...HeroFluid
    }
    hotel: contentfulHotel(name: { eq: "Residences at Biltmore" }) {
      ...HotelContact
    }
    image: contentfulAsset(title: { eq: "Winter Activities in Asheville" }) {
      description
      fluid(maxWidth: 600) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`
